
.a-shadow {
  height: 150px;
  background: linear-gradient(to right, #efefef 45%, #ccc 49%, #ccc 51%, #efefef 55%) 0 0 / 200% 100%;
  animation: gradient 2s linear infinite;
}

@keyframes gradient {
  0% {
    background-position: 20% 50%;
  }
  50% {
    background-position: 80% 50%;
  }
  100% {
    background-position: 20% 50%;
  }
}
/*
#hits{
  position: relative;
  overflow: hidden;
  min-height: 400px;
}

#sloading {
  z-index: 1000;
  position: absolute;
  min-height: 100%;
  background: linear-gradient(to right, #efefef 45%, #ccc 49%, #ccc 51%, #efefef 55%) 0 0 / 200% 100%;
  opacity: 0.5;
  width: 100%;
  left: 0;

  animation: gradient 2s linear infinite;
}



@keyframes gradient {
  0% {
    background-position: 20% 50%;
  }
  50% {
    background-position: 80% 50%;
  }
  100% {
    background-position: 20% 50%;
  }
}*/


/*
.header {
  display: flex;
  align-items: center;
  min-height: 50px;
  padding: 0.5rem 1rem;
  background-image: linear-gradient(284deg, #fedd4e, #fcb43a);
  color: #fff;
  margin-bottom: 1rem;
}


.header a {
  color: #fff;
  text-decoration: none;
}

.header-title {
  font-size: 1.2rem;
  font-weight: normal;
}

.header-title::after {
  content: ' ▸ ';
  padding: 0 0.5rem;
}

.header-subtitle {
  font-size: 1.2rem;
}

.search-panel {
  display: flex;
}

.search-panel__filters {
  flex: 1;
}

.search-panel__results {
  flex: 3;
}

.ais-Highlight-highlighted {
  color: inherit;
  font-size: inherit;
}

#searchbox {
  margin-bottom: 2rem;
}

#pagination {
  margin: 2rem auto;
  text-align: center;
}
*/
